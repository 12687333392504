

















































































































































import {
  computed,
  defineComponent,
  onMounted,
  ref,
} from '@vue/composition-api';
import { Location } from 'vue-router';
import { useParticipantStore } from '@/devicehub/apps/dhStudy/stores/participantStore';
import { dhStudyRouteName } from '@/devicehub/apps/dhStudy/app';
import { Participant, ParticipantDetail } from '@/generated/api/study';
import { useRouter } from '@/devicehub/utils/router';
import { useCopyId } from '@/devicehub/utils/actions';
import PerPage from '@/devicehub/components/PerPage.vue';
import { useI18n } from '@/devicehub/utils/i18n';

export default defineComponent({
  components: { PerPage },
  setup() {
    const participantStore = useParticipantStore();
    const router = useRouter();
    const i18n = useI18n();
    const { onCopyId } = useCopyId((p: ParticipantDetail) => p.participant_id);

    const pageSize = ref(10);

    onMounted(async () => {
      await participantStore.getParticipants();
    });

    const createRoute = { name: dhStudyRouteName('participant-create') };

    const detailRoute = (participant: Participant): Location => {
      return {
        name: dhStudyRouteName('participant-detail'),
        params: {
          id: participant.participant_id as string,
        },
      };
    };

    const onRowClick = (participant: Participant): void => {
      router.push(detailRoute(participant));
    };

    const getDashboardUrl = (participant: Participant): string => {
      return `${participantStore.participantDashboardUrl}${participant.participant_id}`;
    };

    const footer = computed((): string => {
      const total = participantStore.filteredParticipants.length;
      return `${total} ${i18n.tc('dhStudy.participant.this', total)}`;
    });

    return {
      participantStore,
      createRoute,
      detailRoute,
      onRowClick,
      onCopyId,
      getDashboardUrl,
      pageSize,
      footer,
    };
  },
});
