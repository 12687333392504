








import { defineComponent } from '@vue/composition-api';
import ParticipantList from '@/devicehub/apps/dhStudy/components/ParticipantList.vue';

export default defineComponent({
  components: { ParticipantList },
});
